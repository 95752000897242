import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./MomentosBravecto10Anos.css";
import bannerDesk from "../../images/MomentosBravecto10Anos/banner-momentos-bravecto-fim-desktop.webp";
import bannerMob from "../../images/MomentosBravecto10Anos/banner-momentos-bravecto-fim-mobile.webp";
import card1 from "../../images/MomentosBravecto10Anos/MomentosBravectoCard1.webp";
import card2 from "../../images/MomentosBravecto10Anos/MomentosBravectoCard2.webp";
import card3 from "../../images/MomentosBravecto10Anos/MomentosBravectoCard3.webp";
import card4 from "../../images/MomentosBravecto10Anos/MomentosBravectoCard4.webp";
import card5 from "../../images/MomentosBravecto10Anos/MomentosBravectoCard5.webp";
import card6 from "../../images/MomentosBravecto10Anos/MomentosBravectoCard6.webp";
import FirstBoxImage from "../../images/MomentosBravecto10Anos/DogImage.webp";
import JuradosImage from "../../images/MomentosBravecto10Anos/JuradosImage.webp";
import FifthBoxImage from "../../images/MomentosBravecto10Anos/FifthContainerImage.webp";
import SixthBoxImage from "../../images/MomentosBravecto10Anos/SixthContainerImage.webp";
import SixthBoxImage2 from "../../images/MomentosBravecto10Anos/SixthContainerImage2.webp";
import iconeClube from "../../images/MomentosBravecto10Anos/icone_clube.webp";
import iconeFoto from "../../images/MomentosBravecto10Anos/icone_foto.webp";
import iconeHashtag from "../../images/MomentosBravecto10Anos/icone_hashtag.webp";

import Regulamento from "../../docs/Regulamento_aprovado_pela_SECAP_1.pdf";
import FaqQuestion from "./FaqQuestion";



const MomentosBravecto10Anos = () => {
  document.title = "Clube Bravecto | Momentos Bravecto 10 Anos";

  return (
    <div id="MomentosBravecto10Anos">
      <Header />
      <div className="bg-lp first-bg">
        <img src={bannerDesk} alt="banner-momentos-bravecto" className="banner-desk" />
        <img src={bannerMob} alt="banner-momentos-bravecto" className="banner-mob" />
      </div>
        <div className="FirstContainer">
          <div>
            <div>
              <img src={FirstBoxImage} alt="" />
              <div>
                <h1>
                  Você sabia que esse ano o Bravecto<span className="small-symbol">®</span> completa 10 anos desde o seu lançamento no mercado?
                </h1>
                <br/>
                <p>
                  Sim, essa solução veio com tudo e veio para ficar, afinal, o Bravecto<span className="small-symbol">®</span> é o único antiparasitário que oferece 12 semanas de proteção, tanto para o seu pet, quanto para a sua casa. Só ele garante longa duração, segurança, conforto e uma vida feliz e livre de pulgas e carrapatos.
                </p>
                <p>
                  E em comemoração aos 10 anos de Bravecto<span className="small-symbol">®</span>, vamos presentear os tutores mais criativos, nos mais diferenciados momentos em família.
                </p>
              </div>
            </div>
          </div>
          <div className="btn-cta-container">
          <p className="btn-cta">É muito fácil participar, veja só:</p>
          </div>
      </div>
      <div className="second-container">
        <div className="second-container-first-sub">
          <img src={iconeClube} alt="" />
          <div>
            <p>Seja membro do Clube de Vantagens Bravecto<span className="small-symbol">®</span>.</p>
            <span>Se você já for cadastrado, basta conferir se seus dados estão atualizados e se seu cadastro está completo.</span>
          </div>
        </div>
        <div className="second-container-second-sub">
          <img src={iconeFoto} alt="" />
          <div>
            <p>Poste uma foto ou vídeo de um Momento Bravecto<span className="small-symbol">®</span> em família no seu feed do Instagram.</p>
            <span>Ah, e seu perfil precisa ser aberto, tá?</span>
          </div>
        </div>
        <div className="second-container-third-sub">
          <img src={iconeHashtag} alt="" />
          <div>
            <p>Marque a hashtag <span>#momentosbravecto10anos</span> <br/> na legenda.</p>
          </div>
        </div>
        <a href={Regulamento} target="_blank" rel="noopener noreferrer">Leia o regulamento.</a>
        <div className="btn-cta-container">
        <p className="btn-cta">Concorra a prêmios incríveis!</p>
        </div>
      </div>
      <div className="sixth-container">
        <p>
          <span>Serão 10 vencedores,</span> <br/> sendo que:
        </p>
        <div className="sixth-container-card-box">
          <div className="sixth-container-card">
            <img src={SixthBoxImage} alt="" />
            <p>1° Lugar: Uma viagem<span className="small-symbol">*</span></p>
            <div>+</div>
            <p className="sixth-container-card-description"><span>Um acompanhante</span> para <br /> qualquer lugar do mundo.</p>
          </div>
          <div className="sixth-container-card">
            <img src={SixthBoxImage2} alt="" />
            <p id="sixth-container-card-title">2° a 10° Lugar</p>
            <div id="sixth-container-card-plus">+</div>
            <p className="sixth-container-card-description"><span>Um iPhone</span> 15 Black 128 GB <br /> + <span>Quadro do Seu Pet Art</span></p>
          </div>
        </div>
        <p id="sixth-container-regulamento"><span className="small-symbol">*</span>Refere-se a 1 (um) pacote de viagem para destino da escolha do vencedor (ida e volta), com direito 1 (um) acompanhante, com partida e volta <br /> nos dias de escolha do vencedor no valor total de R$25.000,00 (vince e cinco mil reais). Saiba mais no regulamento</p>
        <div className="btn-cta-container">
          <p className="btn-cta">Conheça o nosso júri</p>
        </div>
      </div>
      <div className="third-container">
        <div>
          <img src={JuradosImage} alt="" />
          <div>
            <p>Quando o assunto é família, ningúem melhor que a família mais amada do Brasil não é mesmo?</p>
            <p>A Família SurfDog vai nos ajudar a premiar as melhores e mais criativas postagens.</p>
            <p>FAMÍLIA SURF DOG</p>
            <p>@familiasurfdog</p>
          </div>
        </div>
        <div className="btn-cta-container">
        <p className="btn-cta">Veja algumas inspirações</p>
        </div>
      </div>
      <div className="fourth-container">
        <p>Só com Bravecto<span className="small-symbol">®</span> seu pet e sua família tem 12 semanas de proteção e de muitos momentos inesquecíveis. Queremos ver o que você e seu pet mais curtem fazer juntos! Dentro da casa, na praça, na praia. Momentos de carinho, cuidado ou diversão em família. O que te emociona e te inspira a compartilhar!</p>
        <div className="fourth-container-card-box">
          <img className="fourth-container-card" src={card1} alt="" />
          <img className="fourth-container-card" src={card2} alt="" />
          <img className="fourth-container-card" src={card3} alt="" />
          <img className="fourth-container-card" src={card4} alt="" />
          <img className="fourth-container-card" src={card5} alt="" />
          <img className="fourth-container-card" src={card6} alt="" />
        </div>
        <p><span>#</span>momentos<span>bravecto</span>10anos</p>
        <div className="btn-cta-container">
        <p className="btn-cta">Tá mas e a premiação? Como funciona?</p>
        </div>
      </div>
      <div className="fifth-container">
        <img src={FifthBoxImage} alt="" />
        <p>Serão ao todo 10 ganhadores que irão concorrer a prêmios incríveis. O primeiro lugar garante uma superviagem para qualquer lugar do mundo, com direito a um acompanhante. Já os demais colocados, ganharão um iPhone 15 e um quadro do Seu Pet Art personalizado do seu pet.</p>
        <p className="fifth-container-question">Qual é o período da promoção?</p>
        <p className="fifth-container-answer">De 01 de junho a 31 de agosto de 2024. Serão 12 semanas para você soltar a criatividade e compartilhar com a gente o melhor momento Bravecto<span className="small-symbol">®</span> em família que você puder registrar, seja em foto ou vídeo.</p>
        <p className="fifth-container-question">Qual o critério de avaliação?</p>
        <p className="fifth-container-answer">Os 10 vencedores serão escolhidos pelo nosso embaixador a Família Surf Dog, que são referências quando o assunto é compartilhar momentos em família nas redes.</p>
        <p className="fifth-container-question">O que será avaliado:</p>
        <p className="fifth-container-answer"> - O cadastro completo no Clube de Vantagens Bravecto<span className="small-symbol">®</span>; <br/> - Originalidade e criatividade na publicação; <br/> - História e o contexto da foto/vídeo, afinal, a legenda muitas vezes traduz a emoção e a mensagem daquele momento. Não se esqueça de deixar o seu perfil do Instagram público, e adicionar a <span>#momentosbravecto10anos</span></p>
        <div className="btn-cta-container">
        <p className="btn-cta">Perguntas Frequentes</p>
        </div>
      </div>
      <div className="seventh-container">
        <FaqQuestion question="1. Onde devo fazer meu cadastro?">
          Você deve se cadastrar em Clube de Vantagens Bravecto<span className="small-symbol">®</span>: www.clubebravecto.com.br
        </FaqQuestion>
        <FaqQuestion question="2. O que é o Clube de Vantagens Bravecto?">
          O Clube de Vantagens Bravecto<span className="small-symbol">®</span> foi criado para você, que sempre confiou no Bravecto<span className="small-symbol">®</span> para proteger seu pet. Ao cadastrar seu pet no Clube, você receberá uma série de benefícios e descontos. E, a cada compra de Bravecto<span className="small-symbol">®</span> que você fizer, vocẽ terá acesso à nossa exclusiva Garantia Bravecto<span className="small-symbol">®</span>.
        </FaqQuestion>
        <FaqQuestion question="3. Quando serão divulgados os ganhadores da promoção?">
          Os vencedores serão divulgados até o dia 20/09/2024, através do site da promoção. Entraremos em contato via Instagram, por isso, siga nosso perfil do Instagram @msdfamiliapet e fique de olho no seu inbox!
        </FaqQuestion>
        <FaqQuestion question="4. Existem temas específicos para as publicações no Instagram?">
          Não, nós apenas sugerimos alguns temas no nosso perfil @msdfamiliapet para auxiliar os tutores que não sabem o que compartilhar. O que queremos é ver o que vocÇe e seu pet mais curtem fazer juntos! Dentro de casa, na praça, na praia... Momentos de carinho, cuidado ou diversão em família. O que te emociona e te inspira a compartilhar! Você também pode se inspirar nos perfis dos nossos embaixadores @familiasurfdog, @madaebica @malcomsalsicha e @gabriela.cavallari.
        </FaqQuestion>
        <FaqQuestion question="5. Se eu já tiver cadastro no Clube de Vantagens Bravecto, é só postar o conteúdo no Instagram?">
          Depende, é preciso ter o cadastro completo e atualizado no site clubebravecto.com.br. As informações e dados de cadastro obrigatórios são: nome completo, e-mail, CPF, telefone com DDD, nome do pet, sexo do pet e cadastro de senha (pessoal e intransferível). Mas se você tiver cadastrado corretamente, é só postar a foto ou vídeo no Instagram, marcando a #MomentosBravecto10Anos e explicando a história por trás do conteúdo.
        </FaqQuestion>
        <FaqQuestion question="6. Qual será o critério de desempate?">
          Nossos embaixadores terão a missão de escolher os melhores conteúdos e, como sabemos que vocês são muito criativos, o critério de desempate será a história contada, através da legenda.
        </FaqQuestion>
        <FaqQuestion question="7. Por que preciso deixar meu perfil aberto, durante a promoção?">
          É necessário que o perfil esteja aberto, para que nós consigamos visualizar o conteúdo que você postar durante a promoção e ele ser avaliado por nossos embaixadores.
        </FaqQuestion>
        <FaqQuestion question="8. A postagem com a # pode ser feita em story invés do feed?">
          Nãe, é necessário postar no seu feed, para que a gente não perca o conteúdo, quando chegar o final da campanha e os influenciadores avaliarem as fotos ou vídeos.
        </FaqQuestion>
        <FaqQuestion question="9. Posso fazer meu cadastro e postar diversas vezes?">
          Sim, quanto mais postar, mais chances de ganhar.
        </FaqQuestion>
        <FaqQuestion question="10. Tutores precisam aparecer na publicação?">
          Não. porém, o momento fica muito mais especial quando estamos juntos, não é mesmo?
        </FaqQuestion>
        <FaqQuestion question="11. Escrevi a # da campanha errada, posso corrigir na postagem?">
          Sim, porém, deve ser feito antes do término da campanha, para quando os auditores confirmarem, você não ser desclassificado.
        </FaqQuestion>
      </div>
      <div className="nona-section">
        <a href={Regulamento} target="_blank" rel="noopener noreferrer">Leia o Regulamento</a>
        <p><span className="small-symbol">*</span>Confira o regulamento no site. Imagens meramente ilustrativas. <br /> CERTIFICADO DE AUTORIZAÇÃO SPA/ME. N.° 03.033870/2024</p>
      </div>
      <Footer />
    </div >
  );
};

export default MomentosBravecto10Anos;
